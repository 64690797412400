import {AnySchema} from 'yup';
import React, {useContext} from 'react';
import * as yup from 'yup';

const ValidationService = React.createContext<AnySchema | undefined>(undefined);

export default ValidationService;
export const useValidationSchema = (): AnySchema | undefined => useContext(ValidationService);
export const isFieldRequired = (validationSchema: AnySchema | undefined, fieldName: string): boolean => {
  if(!validationSchema) {
    return false;
  }

  const fieldSchema = yup.reach(validationSchema, fieldName);
  const tests: {name: string}[] = fieldSchema.describe().tests;
  return !!tests.find(test => test.name === 'required')
}