import { Divider, Grid, Tab, Tabs } from "@material-ui/core";
import MainContainer from "../shared/main-container/MainContainer";
import { useContext, useEffect, useState } from "react";
import useGet from "../../shared/hooks/use-get.hook";
import usePost from "../../shared/hooks/use-post.hook";
import { SWIFTPAY } from "../../shared/constants/api-urls";
import { MerchantContext } from "../../App";
import Box from "../shared/box/Box";
import banks from "../../shared/constants/bank-icons.json";
import { PaymentCard } from "./PaymentCard";
import usePut from "../../shared/hooks/use-put.hook";
import NxLoader from "../shared/nxLoader/NxLoader";
import styles from "./PaymentSettings.module.scss";
import clsx from "clsx";
import NxButton from "../shared/nxButton/NxButton";
import { currencyFormat } from "../../shared/utils/currencyFormat";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

type TInstitution = {
  enabled: boolean;
  institution: {
    code: string;
    name: string;
  };
  merchantId: number;
};

type InstitutionResponse = [TInstitution];

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box className={styles.tabContent}>{children}</Box>}
    </div>
  );
}

export const PaymentSettings = () => {
  const [tab, setTab] = useState(0);
  const [channels, setChannels] = useState<InstitutionResponse | undefined>(
    undefined
  );
  const { currentMerchantId } = useContext(MerchantContext);
  const getSwiftpayToken = usePost<{ swiftpaySessionToken: string }, null>(
    `${SWIFTPAY}/${currentMerchantId}/generate-user-token`
  );
  const getChannels = useGet<InstitutionResponse>(
    `${SWIFTPAY}/${currentMerchantId}/institutions`
  );

  const toggleChannel = usePut(`${SWIFTPAY}/${currentMerchantId}/institutions`);

  const handleTabChange = (event, newValue: number) => {
    setTab(newValue);
  };

  const toggleChannelValue = async (code) => {
    const channelToUpdateIndex = channels?.findIndex(
      (channel) => channel.institution.code === code
    );
    if (channelToUpdateIndex !== undefined && channels) {
      const newVal = channels;
      newVal[channelToUpdateIndex].enabled =
        !channels[channelToUpdateIndex].enabled;
      setChannels([...newVal]);

      try {
        const token = await getSwiftpayToken();
        await toggleChannel({ institutionCode: code }, undefined, {
          "X-Ereceipt-Swiftpay-Session-Token": token.swiftpaySessionToken,
        });
      } catch (error) {
        const rollbackVal = channels;
        rollbackVal[channelToUpdateIndex].enabled =
          !channels[channelToUpdateIndex].enabled;
        setChannels([...rollbackVal]);
      }
    }
  };

  useEffect(() => {
    async function callApi() {
      if (currentMerchantId) {
        const token = await getSwiftpayToken();
        const channels = await getChannels(undefined, undefined, {
          "X-Ereceipt-Swiftpay-Session-Token": token.swiftpaySessionToken,
        });
        setChannels(channels);
      }
    }
    callApi();
  }, [currentMerchantId]);

  return (
    <MainContainer>
      <NxLoader
        loaded={Boolean(channels && currentMerchantId)}
        className={styles.tabContent}
      >
        <>
          <h3>Payment settings</h3>
          <div className={styles.tabsContainer}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Tabs value={tab} onChange={handleTabChange}>
                <Tab label="Payments Channels" />
                <Tab label="Payment Products" />
              </Tabs>
            </div>

            <Divider />
          </div>
          <TabPanel value={tab} index={0}>
            <Grid container xs={8} className={styles.tabContentBox}>
              <div className={styles.columnHeader}>
                <h3>Institutions</h3>
                <Divider />
              </div>
              <Grid container spacing={2}>
                {channels?.map(({ enabled, institution }) => (
                  <Grid item key={institution.code} xs={3}>
                    <PaymentCard
                      key={institution.name}
                      logoUrl={banks[institution.code]}
                      name={institution.name}
                      value={enabled}
                      code={institution.code}
                      toggleValue={toggleChannelValue}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid
              container
              xs={4}
              className={clsx(styles.tabContentBox, styles.fitContent)}
            >
              <h3>Go to your Payment Portal</h3>
              <p style={{ fontWeight: 300, paddingBottom: "24px" }}>
                Click the button below to open your dedicated Payment Portal
                that your customers can use to pay online for your products and
                services
              </p>
              <a
                href="https://pay.qa.swiftpay.ph/billers/products/19?category=Other"
                target="_blank"
                rel="noreferrer"
              >
                <NxButton>Go to Payment Portal</NxButton>
              </a>
            </Grid>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Grid container xs={8}>
              <Grid container>
                <Grid container direction="column">
                  <Grid
                    container
                    className={clsx(
                      styles.tabContentBox,
                      styles.productSpacing
                    )}
                  >
                    <Grid
                      container
                      justifyContent="space-between"
                      className={styles.headerPadding}
                    >
                      <h3>Real Property Tax</h3>
                    </Grid>
                    <Grid
                      container
                      justifyContent="space-between"
                      className={styles.productContainer}
                    >
                      <Grid item direction="column">
                        <div className={styles.productItem}>
                          <div className={styles.label}>Reference number</div>
                          <div className={styles.value}>3728h910aaa11000</div>
                        </div>
                        <div className={styles.productItem}>
                          <div className={styles.label}>Email address</div>
                          <div className={styles.value}>
                            jake.katinsky@gmail.com
                          </div>
                        </div>
                      </Grid>
                      <Grid item direction="column">
                        <div className={styles.productItem}>
                          <div className={styles.label}>
                            Tax Declaration number
                          </div>
                          <div className={styles.value}>64648219010</div>
                        </div>
                        <div className={styles.productItem}>
                          <div className={styles.label}>Contact number</div>
                          <div className={styles.value}>+63 9948041500</div>
                        </div>
                      </Grid>
                      <Grid item direction="column">
                        <div className={styles.productItem}>
                          <div className={styles.label}>Amount due</div>
                          <div className={styles.value}>
                            {currencyFormat(72782.19)}
                          </div>
                        </div>
                        <div className={styles.productItem}>
                          <div className={styles.label}>Period</div>
                          <div className={styles.value}>Annual</div>
                        </div>
                      </Grid>
                      <Grid item direction="column">
                        <div className={styles.productItem}>
                          <div className={styles.label}>Name</div>
                          <div className={styles.value}>Jake Katinsky</div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className={clsx(styles.tabContentBox, styles.productSpacing)}
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    className={styles.headerPadding}
                  >
                    <h3>Business Tax / Permit / License</h3>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    className={styles.productContainer}
                  >
                    <Grid item direction="column">
                      <div className={styles.productItem}>
                        <div className={styles.label}>Reference number</div>
                        <div className={styles.value}>3728h910aaa11000</div>
                      </div>
                      <div className={styles.productItem}>
                        <div className={styles.label}>Email address</div>
                        <div className={styles.value}>
                          jake.katinsky@gmail.com
                        </div>
                      </div>
                    </Grid>
                    <Grid item direction="column">
                      <div className={styles.productItem}>
                        <div className={styles.label}>Amount due</div>
                        <div className={styles.value}>
                          {currencyFormat(72782.19)}
                        </div>
                      </div>
                      <div className={styles.productItem}>
                        <div className={styles.label}>Contact number</div>
                        <div className={styles.value}>+63 9948041500</div>
                      </div>
                    </Grid>
                    <Grid item direction="column">
                      <div className={styles.productItem}>
                        <div className={styles.label}>Business name</div>
                        <div className={styles.value}>Estoria</div>
                      </div>
                      <div className={styles.productItem}>
                        <div className={styles.label}>Period</div>
                        <div className={styles.value}>Annual</div>
                      </div>
                    </Grid>
                    <Grid item direction="column">
                      <div className={styles.productItem}>
                        <div className={styles.label}>Payor Name</div>
                        <div className={styles.value}>Jake Katinsky</div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className={clsx(styles.tabContentBox, styles.productSpacing)}
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    className={styles.headerPadding}
                  >
                    <h3>Market Stall / Commercial space permite</h3>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    className={styles.productContainer}
                  >
                    <Grid item direction="column">
                      <div className={styles.productItem}>
                        <div className={styles.label}>Reference number</div>
                        <div className={styles.value}>3728h910aaa11000</div>
                      </div>
                      <div className={styles.productItem}>
                        <div className={styles.label}>Email address</div>
                        <div className={styles.value}>
                          jake.katinsky@gmail.com
                        </div>
                      </div>
                    </Grid>
                    <Grid item direction="column">
                      <div className={styles.productItem}>
                        <div className={styles.label}>Amount due</div>
                        <div className={styles.value}>
                          {currencyFormat(72782.19)}
                        </div>
                      </div>
                      <div className={styles.productItem}>
                        <div className={styles.label}>Contact number</div>
                        <div className={styles.value}>+63 9948041500</div>
                      </div>
                    </Grid>
                    <Grid item direction="column">
                      <div className={styles.productItem}>
                        <div className={styles.label}>Business name</div>
                        <div className={styles.value}>Estoria</div>
                      </div>
                      <div className={styles.productItem}>
                        <div className={styles.label}>Period</div>
                        <div className={styles.value}>Annual</div>
                      </div>
                    </Grid>
                    <Grid item direction="column">
                      <div className={styles.productItem}>
                        <div className={styles.label}>Payor name</div>
                        <div className={styles.value}>Jake Katinsky</div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className={clsx(styles.tabContentBox, styles.productSpacing)}
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    className={styles.headerPadding}
                  >
                    <h3>Real Property Tax</h3>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    className={styles.productContainer}
                  >
                    <Grid item direction="column">
                      <div className={styles.productItem}>
                        <div className={styles.label}>Ticket number</div>
                        <div className={styles.value}>3728h910aaa11000</div>
                      </div>
                      <div className={styles.productItem}>
                        <div className={styles.label}>Email address</div>
                        <div className={styles.value}>
                          jake.katinsky@gmail.com
                        </div>
                      </div>
                    </Grid>
                    <Grid item direction="column">
                      <div className={styles.productItem}>
                        <div className={styles.label}>Name</div>
                        <div className={styles.value}>Jack Snow</div>
                      </div>
                      <div className={styles.productItem}>
                        <div className={styles.label}>Contact number</div>
                        <div className={styles.value}>+63 9948041500</div>
                      </div>
                    </Grid>
                    <Grid item direction="column">
                      <div className={styles.productItem}>
                        <div className={styles.label}>Amount due</div>
                        <div className={styles.value}>
                          {currencyFormat(72782.19)}
                        </div>
                      </div>
                    </Grid>
                    <Grid item direction="column">
                      <div className={styles.productItem}>
                        <div className={styles.label}>Violation type</div>
                        <div className={styles.value}>Illegal parking</div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              xs={4}
              className={clsx(styles.tabContentBox, styles.fitContent)}
            >
              <h3>Go to your Payment Portal</h3>
              <p style={{ fontWeight: 300, paddingBottom: "24px" }}>
                Click the button below to open your dedicated Payment Portal
                that your customers can use to pay online for your products and
                services
              </p>
              <a
                href="https://pay.qa.swiftpay.ph/billers/products/19?category=Other"
                target="_blank"
                rel="noreferrer"
              >
                <NxButton>Go to Payment Portal</NxButton>
              </a>
            </Grid>
          </TabPanel>
        </>
      </NxLoader>
    </MainContainer>
  );
};
